export default {

  getIsDebugEnabled: (state) => {
    return state.isDebugEnabled;
  },
  getLocale: (state) => {
    return state.locale;
  },

  //-----SCENE CHANGING-----

  getIsNewSceneLoading: (state) => {
    return state.isNewSceneLoading;
  },

  //-----SCREEN DIMENSIONS-----
  getTrueVW: (state) => {
    return state.trueVW;
  },
  getTrueVH: (state) => {
    return state.trueVH;
  },
  getDesignWidthMobile: (state) => {
    return state.designWidthMobile;
  },
  getDesignHeightMobile: (state) => {
    return state.designHeightMobile;
  },
  getDesignWidthDesktop: (state) => {
    return state.designWidthDesktop;
  },
  getDesignHeightDesktop: (state) => {
    return state.designHeightDesktop;
  },
};
