///////////////////////////////////////////////////////////////////////////
/*
Name: 			analytics.js

Description:    How to Swap Analytics Type: change the method being assign to this.analyticsMethod

Usage:			import analytics from "./analytics.js"

*/
///////////////////////////////////////////////////////////////////////////

const analytics = {
  currentPage: "",
  timeStartedPage: 0,

  timers: {},

  pageTitle: "",
  countryCode: "en-US",

  //NOTE: You cannot set the method here, you have to set it in init
  analyticsMethod: null,

  dataLayerDefault: {
    'event': '',
    'page_title': 'venomize my pet {{page_title}}',
    'gpms_id': '11257352',
    'property_title': 'venom the last dance',
    'property_type': 'user generated content web app',
    'site_country': 'us',
    'genres': 'action, adventure',
    'content_type': 'microsite',
    'subcontent_type': 'venomize my pet webapp {{country_code}} microsite',
    'division': 'mp',
  },

  init(countryCode) {
    //this.startTimer("OverallDwellTime");
    //console.log("init analytics");
    //window.addEventListener("focus", this.focusedPage.bind(this));
    //window.addEventListener("blur", this.blurPage.bind(this));

    //Change this.dataLayerAnalytics to whatever analytics method the project is using
    this.analyticsMethod = this.dataLayerAnalytics;
    this.countryCode = countryCode;
  },

  setPage(pageTitle) {
    if (!this.analyticsMethod)
      this.analyticsMethod = this.dataLayerAnalytics;
    this.pageTitle = pageTitle;
    this.analyticsMethod('venomize_my_pet_page_change')
  },

  focusedPage() {
    this.startTimer("OverallDwellTime");
    this.timeStartedPage = Date.now();
  },
  blurPage() {
    if (this.currentPage !== "")
      this.dwellTimeOnPageEvent(this.currentPage, this.timeStartedPage);
    this.sendEventForTimer("DwellTime", "Overall", "OverallDwellTime");
  },


  //Analytics Method 2:
  dataLayerAnalytics(event) {
    console.log(
      "analytics event - data layer: " + event
    );
    let dataLayerPush = this.dataLayerDefault;
    dataLayerPush.event = event;
    dataLayerPush.page_title = 'venomize my pet '+this.pageTitle;
    dataLayerPush.subcontent_type = 'venomize my pet webapp '+this.countryCode+' microsite';
    dataLayerPush.site_country = this.countryCode;

    window.dataLayer.push(dataLayerPush);
  },

  pageVisitEvent(pageId) {
    this.analyticsMethod("PageVisit", pageId);
  },

  dwellTimeOnPageEvent(pageId, startTime) {
    let dwellOnLastPage = (Date.now() - startTime) / 1000;
    this.analyticsMethod("DwellTime", pageId, null, dwellOnLastPage);
  },

  changePage(pageId) {
    if (pageId !== this.currentPage) {
      //Log the dwell time on the last page.
      if (this.timeStartedPage !== 0)
        this.dwellTimeOnPageEvent(this.currentPage, this.timeStartedPage);

      this.currentPage = pageId;
      this.pageVisitEvent(pageId);
      this.timeStartedPage = Date.now();
    }
  },

  startTimer(timerId) {
    this.timers[timerId] = Date.now();
  },
  sendEventForTimer(category, action, timerId) {
    if (this.timers[timerId] != null) {
      let time = (Date.now() - this.timers[timerId]) / 1000;
      this.analyticsMethod(category, action, null, time);
    } else {
      console.log(
        "Tried to send an event for timer " +
          timerId +
          " which hasn't been started."
      );
    }
  },
};

export default analytics;
