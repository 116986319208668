export default {

  setIsDebugEnabled(state, target) {
    state.isDebugEnabled = target;
  },
  setLanguageSelectorEnlarged(state, target) {
    state.isLanguageSelectorEnlarged = target;
  },
  setLocale(state, target) {
    state.locale = target;
  },
  reset(state) {
    state.shareOpen = false;
    state.photoOpen = false;
    state.placed = false;
  },

  //-----SCENE CHANGING-----
  setIsNewSceneLoading(state, target) {
    state.isNewSceneLoading = target;
  },

  //-----SCREEN DIMENSIONS-----
  setTrueVW(state, target) {
    state.trueVW = target;
  },
  setTrueVH(state, target) {
    state.trueVH = target;
  },
};
