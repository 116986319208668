<script setup>

import {inject, ref} from "vue";
import mediapipe from "@/js/mediapipe";
import axios from "axios";
import {useStore} from "vuex";

const translate = inject('translate')

const store = useStore();
const handleFiles = async function () {
  console.log("uploaded")
  // Get the file from the input
  const file = event.target.files[0];

  if (file) {
    // Create a URL for the selected file
    const imageUrl = URL.createObjectURL(file);

    store.commit("setUploadedPictureSrc", imageUrl);
    store.commit("uploadedImageFile", file);

  }
}

</script>

<template>
  <div class="upload-button-container">
    <div class="upload-button">
      <div class="background-image">
        <div class="button-text venom-text-header" v-html="translate('choose-photo-button')">
        </div>
      </div>
      <input id = "test-upload" type="file" class = "upload-button-input" accept="image/png, image/jpeg" @change="handleFiles" >
    </div>
  </div>
</template>

<style scoped lang="scss">

@import '../sass/GlobalClasses';
@import '../sass/Settings';

.upload-button-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .upload-button {
    width: vw(817);
    height: vw(222);
    border-radius: vw(428);
    -webkit-backdrop-filter: blur(23.4px);
    backdrop-filter: blur(23.4px);
    background-color: rgba(0, 0, 0, 0.2);
    padding: vw(16);
    .upload-button-input {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
    .background-image {
      pointer-events: none;
      position: relative;
      width: 100%;
      height: 100%;
      background-image: url("../../public/assets/imgs/choose-a-photo-button.png");
      background-size: cover;
      .button-text {
        position: absolute;
        left: 62%;
        top: 50%;
        font-family: Lato;
        font-size: vw(48);
        font-weight: bold;
        color: #fff;
        width: 46.6vw;
        transform: translate(-50%, -50%);
      }
    }
  }
}

@media screen and (min-aspect-ratio: 1/1) {

  .upload-button-container {
    pointer-events: all;
    .upload-button {
      position: relative;
      left: vw(559, $design-width-desktop);
      transform: translateX(-50%);
      width: vw(540, $design-width-desktop);
      height: vw(130, $design-width-desktop);
      border-radius: vw(130, $design-width-desktop);
      padding: 0;
      -webkit-backdrop-filter: none;
      backdrop-filter: none;
      background-color: transparent;
      .background-image {
        background-image: url("../../public/assets/imgs/choose-a-photo-desktop.png");
        .button-text {
          position: absolute;
          left: 62%;
          top: 50%;
          font-family: Lato;
          font-size: vw(32, $design-width-desktop);
          font-weight: bold;
          color: #fff;
          width: 46.6vw;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
</style>