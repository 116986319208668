<script setup>

import { inject } from 'vue'
import {useStore} from "vuex";
import audioControl from "@/js/audioControl";
const translate = inject('translate')
const props = defineProps({
  header: { default: "", type: String },
  body: { default: "", type: String },
  secondbody: { default: "", type: String },
  button: { default: "error-no-animal-try-again-button", type: String }
});

const store = useStore();
const tryAgain = function () {
  audioControl.restartAudio("click");
  store.commit("setFailScreen", "");
  store.commit("setState", "photo-input");
  store.commit("setLoading", false);
};

</script>

<template>
  <div class="fail-container">
    <img class="venom-face" src="../../../public/assets/imgs/venom-vector-face.png"/>
    <img class="caution-icon" src="../../../public/assets/imgs/caution-icon.png"/>
    <div class="venom-text-header" v-html="translate(header)"></div>
    <div class="venom-text-body" v-html="translate(body)"></div>
    <div class="venom-text-body italics-body" v-if="secondbody != ''" v-html="translate(secondbody)"></div>
    <div class="venom-button" v-if="button != ''" @click="tryAgain">
      <div v-html="translate(button)"></div>
    </div>
    <img class="tendrils" src="../../../public/assets/imgs/tendril-overlay-2.png"/>
  </div>
</template>

<style scoped lang="scss">

@import '../../sass/GlobalClasses';
@import '../../sass/Settings';

.fail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: vw(500);
  .venom-face {
    margin-top: vw(256);
    width: vw(473);
    height: auto;
    z-index: 1;
  }
  .caution-icon {
    margin-top: vw(93);
    width: vw(214);
    height: auto;
    z-index: 1;
  }
  .venom-text-header {
    margin-top: vw(70);
    z-index: 1;
  }
  .venom-text-body {
    margin-top: vw(10);
    width: vw(875);
    z-index: 1;
  }
  .italics-body {
    width: vw(875);
    margin-top: vw(15);
    font-size: vw(40);
    font-style: italic;
  }
  .venom-button {
    margin-top: vw(54);
    width: vw(628);
    height: vw(128);
  }
  .tendrils {
    position: relative;
    top: vw(-200);
    width: 100%;
  }
}
@media screen and (min-aspect-ratio: 1/1) {

  .fail-container {
    position: relative;
    padding-bottom: vw(1000, $design-width-desktop);
    width: fit-content;
    left: vw(884, $design-width-desktop);
    div {
    }
    .venom-face {
      position: absolute;
      right: vw(1004, $design-width-desktop);
      margin-top: vw(256, $design-width-desktop);
      width: vw(349, $design-width-desktop);
    }
    .caution-icon {
      margin-top: vw(193, $design-width-desktop);
      width: vw(160, $design-width-desktop);
    }
    .venom-text-header {
      margin-top: vw(50, $design-width-desktop);
      font-size: vw(30, $design-width-desktop);
      left: 0;
    }
    .venom-text-body {
      margin-top: vw(15, $design-width-desktop);
      font-size: vw(30, $design-width-desktop);
      width: vw(854, $design-width-desktop);
      left: 0;
    }
    .italics-body {
      width: vw(854, $design-width-desktop);
      margin-top: vw(5, $design-width-desktop);
      font-size: vw(30, $design-width-desktop);
      left: 0;
    }
    .venom-button {
      margin-top: vw(54, $design-width-desktop);
      width: vw(420, $design-width-desktop);
      height: vw(90, $design-width-desktop);

      div {
        font-size: vw(30, $design-width-desktop);
      }
      border: solid vw(8, $design-width-desktop) #bf1f21;
    }
    .venom-button:active {
      border: $button-outline-active solid vw(8, $design-width-desktop);
    }
    .tendrils {
      display: none;
    }
  }
}

</style>