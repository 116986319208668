<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import {inject} from 'vue';
const vw = inject('vw');
// grab state
const store = useStore();

const vuedProperties = ref([
  {
    name: "loadStarted",
    store: store.getters.loadStarted,
  },
]);

const downloadLog = () => {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," +
      encodeURIComponent(vuedProperties.value.toString())
  );
  element.setAttribute("download", "greatest_store_ever.txt");

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};
</script>

<template>
  <div class="module-container">
    <div class="background" />
    <div
      class="debug-text"
      v-bind:key="properties.name"
      v-for="properties in vuedProperties"
    >
      <div class="vue-properties">
        {{ properties.name + ": " + properties.store }}
      </div>
    </div>
    <div class="download-area">
      <div class="download-button">
        <div class="text" v-on:click="downloadLog">Download Log</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../sass/GlobalClasses";
@import "../../sass/Settings";

.module-container {
  @extend %absolute-fill;

  .background {
    @extend %absolute-fill;
    background-color: black;
    opacity: 0.3;
  }

  .debug-text {
    color: white;
    width: 100%;
    position: absolute;
    bottom: 20%;
    left: 0;

    .vue-property {
      width: 100%;
      height: vw(100);
      position: relative;
    }
  }

  .download-area {
    width: 100%;
    position: absolute;
    bottom: 5%;
    display: flex;
    justify-content: center;

    .download-button {
      width: 50%;
      height: vw(100);
      pointer-events: auto;
      position: relative;
      background-color: white;

      .text {
        display: flex;
        justify-content: center;
        position: relative;
      }
    }
  }
}
</style>
