<script setup>
import {computed, inject, onMounted, ref, useTemplateRef} from 'vue'
import { useStore } from "vuex";
import analytics from "@/js/analytics";
import venomizer from "@/js/venomizer";
const translate = inject('translate')

const store = useStore();

let swipAngle = ref(0);
let swipLength = ref(0);
const photoAspectRatio = computed(() => store.getters.photoAspectRatio);


onMounted(() => {
  analytics.setPage("photo input screen");

  let uploadedPicture = document.getElementById('uploaded-picture');
  uploadedPicture.onload = async function () {
    let aspectRatio = await venomizer.cropImage(uploadedPicture);
    store.commit('photoAspectRatio', aspectRatio);
    swipLength.value = Math.sqrt(Math.pow(uploadedPicture.width, 2) + Math.pow(uploadedPicture.height, 2));

    swipAngle.value = Math.acos(uploadedPicture.width / swipLength.value) * (180 / Math.PI);
    swipLength.value = swipLength.value * 1.2;
    store.commit("setState", "photo-confirm");
  };
});
</script>

<template>
  <div class="photo-input-container">
    <img class = "tendril-overlay" src="../../public/assets/imgs/tendrals-footer-upload-photo-screen.png"/>
    <img class="correct-incorrect-sample" src="../../public/assets/imgs/correct-incorrect-sample-img.png"/>
    <div class="venom-text-body instructions-text" v-html="translate('upload-guidance-instruction-1')"></div>
    <div class="venom-text-body instructions-text-2" v-html="translate('upload-guidance-instruction-2')"></div>
  </div>
</template>

<style lang="scss">

@import '../sass/GlobalClasses';
@import '../sass/Settings';
.photo-input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  top: vw(-300);
  align-items: center;
  .correct-incorrect-sample {
    position: relative;
    width: vw(888);
  }
  .instructions-text {
    margin-top: vw(54);
    width: vw(856);
  }
  .instructions-text-2 {
    width: vw(856);
    margin-top: vw(10);
    font-size: vw(40);
    white-space: nowrap;
    font-style: italic;
    margin-bottom: vw(500);
  }

  .tendril-overlay {
    position: absolute;
    top: vw(1050);
    width: 100%;
    height: auto;
  }
}

@media screen and (min-aspect-ratio: 1/1) {
  .photo-input-container {
    position: relative;
    display: flex;
    flex-direction: column;
    top: vw(60, $design-width-desktop);
    align-items: center;
    .correct-incorrect-sample {
      position: absolute;
      top: vw(-590, $design-width-desktop);
      right: vw(270, $design-width-desktop);
      width: vw(708, $design-width-desktop);
    }
    .instructions-text {
      font-size: vw(30, $design-width-desktop);
      margin-top: vw(54, $design-width-desktop);
      width: vw(1357, $design-width-desktop);
    }
    .instructions-text-2 {
      width: vw(1357, $design-width-desktop);
      margin-top: vw(10, $design-width-desktop);
      font-size: vw(25, $design-width-desktop);
      margin-bottom: 0;
    }

    .tendril-overlay {
      display: none;
    }
  }
}
</style>