<script setup>

import {inject, onMounted} from 'vue'
import {useStore} from "vuex";
import analytics from "@/js/analytics";
import audioControl from "@/js/audioControl";
const translate = inject('translate')

const store = useStore();

const back = function () {
  audioControl.restartAudio("click");
  analytics.analyticsMethod('venomize_my_pet_choose_again');
  setTimeout(function () {
    store.commit("setState", "photo-input");
  }, 100)
}

onMounted(() => {
  analytics.setPage("photo confirmation screen");
});
</script>

<template>
  <div class="photo-preview-container">

    <img class = "tendril-overlay" src="../../public/assets/imgs/tendril-overlay-2.png"/>
    <div class = "venom-button confirm-button" @click="$emit('handle-confirm')" ontouchstart>
      <div v-html="translate('captured-looks-great-button')"></div>
    </div>
    <div class="venom-text-header middle-text" v-html="translate('captured-button-seperator-text')"></div>
    <div class = "venom-button back-button" @click="back" ontouchstart>
      <div v-html="translate('captured-choose-again-button')"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../sass/GlobalClasses';
@import '../sass/Settings';

.photo-preview-container {
  position: relative;
  display: flex;
  flex-direction: column;
  top: vw(100);
  align-items: center;
  .venom-button {
    width: vw(620);
    height: vw(130);
  }
  .middle-text {
    margin-top: vw(50);
    margin-bottom: vw(50);
    font-size: vw(78);
    font-weight: 900;
  }
  .tendril-overlay {
    position: absolute;
    top: vw(160);
    width: 100%;
    height: auto;
  }
}
.photo-preview-container.taller-photo {
  top: vw(200);
}
@media screen and (min-aspect-ratio: 1/1) {

  .photo-preview-container {
    top: vw(-160, $design-width-desktop);
    left: vw(558, $design-width-desktop);
    transform: translateX(-50%);
    width: fit-content;
    .venom-button {
      width: vw(420, $design-width-desktop);
      height: vw(90, $design-width-desktop);
      border-radius: vw(65, $design-width-desktop);
      div {
        font-size: vw(30, $design-width-desktop);
      }
    }
    .middle-text {
      margin-top: vw(30, $design-width-desktop);
      margin-bottom: vw(30, $design-width-desktop);
      font-size: vw(50, $design-width-desktop);
    }
    .tendril-overlay {
      display: none;
    }
  }

  .photo-preview-container.taller-photo {
    top: vw(-160, $design-width-desktop);
  }
}
</style>