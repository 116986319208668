import {
  ObjectDetector,
  ImageClassifier,
  FilesetResolver,
  Detection,
  ObjectDetectionResult
} from "@mediapipe/tasks-vision";
import animalDataset from "@/data/animalDataset.json";

const mediaPipe = {

  initialized: false,
  objectDetector: ObjectDetector,
  imageClassifier: ImageClassifier,
  runningMode: "IMAGE",

  async init() {
    // Initialize Vision
    const vision = await FilesetResolver.forVisionTasks(
      "./assets/wasm"
    );

    //initialize Object Detector
    this.objectDetector = await ObjectDetector.createFromOptions(vision, {
      baseOptions: {
        modelAssetPath: `./assets/wasm/float16/efficientdet_lite0.tflite`,
        delegate: "GPU"
      },
      scoreThreshold: 0.2,
      runningMode: this.runningMode
    });

    // Initialize Image Classifier
    this.imageClassifier = await ImageClassifier.createFromOptions(vision, {
      baseOptions: {
        modelAssetPath: `./assets/wasm/float32/efficientnet_lite0.tflite`
        // NOTE: For this demo, we keep the default CPU delegate.
      },
      maxResults: 2,
      runningMode: this.runningMode
    });
  },

  handleObjectDetections(result) {
    let hasFoundPerson = false;
    let hasFoundAnimalSimple = false;
    for (let detection of result.detections) {
      console.log(detection.categories)
      if (detection.categories[0].categoryName === "person") {
        console.log("Category person found!");
        hasFoundPerson = true;
      }
      if (detection.categories[0].categoryName === "dog") {
        console.log("Category dog found!");
        hasFoundAnimalSimple = true;
      }
      if (detection.categories[0].categoryName === "cat") {
        console.log("Category cat found!");
        hasFoundAnimalSimple = true;
      }
      if (detection.categories[0].categoryName === "horse") {
        console.log("Category horse found!");
        hasFoundAnimalSimple = true;
      }
    }

    return {hasFoundPerson, hasFoundAnimalSimple}
  },

  handleImageClassifications (result) {
    let hasFoundAnimalComplex = false;
    for (let classification of result[0].categories) {
      console.log(classification.categoryName)
      if(animalDataset.animals.includes(classification.categoryName)){
        hasFoundAnimalComplex = true;
        console.log("Category " + classification.categoryName + " found!");
      }
    }

    return {hasFoundAnimalComplex}
  }


};

export default mediaPipe;
