<script setup>

import {computed, inject, onMounted, ref} from 'vue'
import {useStore} from "vuex";
import analytics from "@/js/analytics";
import audioControl from "@/js/audioControl";

import {
  isAndroid
} from "mobile-device-detect";

const translate = inject('translate')

const store = useStore();

const downloadablePictureSrc = computed(() => store.getters.getDownloadablePictureSrc);
const uploadedPictureSrc = computed(() => store.getters.getUploadedPictureSrc);

const props = defineProps({
  animationFinished: { default: false, type: Boolean }
});

const normalDownload = ref(false);

const mediaQuery = window.matchMedia('(min-aspect-ratio: 1/1)')
if (mediaQuery.matches || isAndroid) {
  normalDownload.value = true;
}

const download = function () {
  audioControl.restartAudio("click");
  analytics.analyticsMethod('venomize_my_pet_download');
  if (!normalDownload.value)
    share("venomized.jpg", downloadablePictureSrc.value);
}
const downloadOriginal = function () {
  audioControl.restartAudio("click");
  analytics.analyticsMethod('venomize_my_pet_download_original');
  if (!normalDownload.value)
    share("venomized-original.jpg", uploadedPictureSrc.value);
}
const back = function () {
  audioControl.restartAudio("click");
  analytics.analyticsMethod('venomize_my_pet_venomize_again');
  setTimeout(function () {
    store.commit("setState", "photo-input");
    store.commit("setUploadedPictureSrc", "");
    store.commit("setVenomizedPictureSrc", "");
  }, 100)
}
const linkout = function (url) {
  audioControl.restartAudio("click");
  analytics.analyticsMethod('venomize_my_pet_get_tickets_now');
  window.open(url, "_blank");
};
const socialLink = function (analyticEvent) {
  analytics.analyticsMethod(analyticEvent);
}
const socialLinkExists = function (key) {
  return key != translate(key);
}
const share = async function (fileName, image) {
  const blob = await fetch(image).then(r=>r.blob())
  let file = new File([blob], fileName, { type: 'image/jpeg' });
  navigator.share({
    files: [file]
  })
};

onMounted(() => {
  analytics.setPage("venomized output screen")
});
</script>

<template>
  <div class="venomized-output-container">
    <img class = "tendril-overlay" src="../../public/assets/imgs/tendril-overlay-2.png"/>
    <div
        class="venom-button download-button"
        ontouchstart=""
        @click="download"
        :class="{'animation-finished': animationFinished}"
        v-if="!normalDownload"
    >
    <div v-html="translate('output-download-button')"></div>
    </div>
    <div
        class="download-original"
        ontouchstart=""
        @click="downloadOriginal"
        v-if="!normalDownload"
    >
      <img src="../../public/assets/imgs/download-icon.png" class="download-icon">
      <div v-html="translate('output-download-original-button')" class="download-text venom-text-body"></div>
    </div>
    <a
        target="_blank"
        download="venomized-pet.jpg"
        type="image/jpeg"
        class="venom-button download-button"
        v-bind:href="downloadablePictureSrc"
        ontouchstart=""
        @click="download"
        :class="{'animation-finished': animationFinished}"
        v-if="normalDownload"
    >
      <div v-html="translate('output-download-button')"></div>
    </a>
    <a
        target="_blank"
        download="venomized-pet-original.jpg"
        type="image/jpeg"
        class="download-original"
        v-bind:href="uploadedPictureSrc"
        ontouchstart=""
        @click="downloadOriginal"
        v-if="normalDownload"
    >
      <img src="../../public/assets/imgs/download-icon.png" class="download-icon">
      <div v-html="translate('output-download-original-button')" class="download-text venom-text-body"></div>
    </a>
    <div class="venom-text-body share-text" v-html="translate('output-share-text')" >
    </div>
    <div class="social-icons-body">
      <a v-if = "socialLinkExists('output-instagram-share')" target="_blank" :href="translate('output-instagram-share')" @click="socialLink('venomize_my_pet_instagram_share')"><img src="../../public/assets/imgs/instagram.png" class="social-icon"></a>
      <a v-if = "socialLinkExists('output-tiktok-share')" target="_blank" :href="translate('output-tiktok-share')" @click="socialLink('venomize_my_pet_tiktok_share')"><img src="../../public/assets/imgs/tik-tok.png" class="social-icon"></a>
      <a v-if = "socialLinkExists('output-x-share')" target="_blank" :href="translate('output-x-share')" @click="socialLink('venomize_my_pet_x_share')"><img src="../../public/assets/imgs/x.png" class="social-icon"></a>
      <a v-if = "socialLinkExists('output-facebook-share')" target="_blank" :href="translate('output-facebook-share')" @click="socialLink('venomize_my_pet_facebook_share')"><img src="../../public/assets/imgs/facebook.png" class="social-icon"></a>
    </div>
    <div class = "venom-button tickets-button" ontouchstart @click="linkout(translate('tickets-link'))">
      <div v-html="translate('output-get-tickets-button')"></div>
    </div>
    <div class = "venom-button back-button" @click="back" ontouchstart>
      <div v-html="translate('output-venomize-again-button')"></div>
    </div>
  </div>

</template>

<style scoped lang="scss">
@import '../sass/GlobalClasses';
@import '../sass/Settings';

.venomized-output-container {
  position: relative;
  display: flex;
  flex-direction: column;
  top: vw(100);
  align-items: center;
  .venom-button {
    width: vw(440);
    height: vw(100);
    div {
      font-size: vw(29);
    }
  }
  .download-button {
    width: vw(620);
    height: vw(130);
    div {
      font-size: vw(48);
    }
    margin-bottom: vw(48);
    border: solid vw(8) #292929;
    background-color: #1a1a1a;
    pointer-events: none;
    z-index: 1;
  }
  .download-button.animation-finished {
    pointer-events: all;
    border: solid vw(8) #bf1f21;
    background-color: #000;
  }
  .download-button.animation-finished:hover {
    background-color: #220001;
  }
  .download-button.animation-finished:active {
    border: $button-outline-active solid vw(8);
  }
  .download-original {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: vw(30);
    z-index: 1;
    .download-icon {
      width: vw(55);
      height: auto;
      margin-right: vw(32);
    }
    .download-text {
      position: relative;
      top: vw(-10);
      font-size: vw(34);
      font-weight: bold;
      line-height: 0.9;
      color: #fff;
      text-decoration: underline;
      text-decoration-thickness: vw(2);
      text-underline-offset: vw(10);
    }
  }
  .share-text {
    font-size: vw(34);
    margin-bottom: vw(20);
    z-index: 1;
  }
  .social-icons-body {
    display: flex;
    flex-direction: row;
    .social-icon {
      height: vw(93);
      width: auto;
      margin-left: vw(50);
      margin-right: vw(50);
    }
    margin-bottom: vw(100);
    z-index: 1;
  }
  .tickets-button {
    width: vw(620);
    height: vw(135);
    border-radius: vw(65);
    box-shadow: 0 0 vw(86) 0 rgba(191, 31, 33, 0.58);
    border: solid vw(8) #afafaf;
    background-image: linear-gradient(102deg, #000 5%, #2c2c2c 97%);
    margin-bottom: vw(100);
    div {
      font-size: vw(48);
    }
    z-index: 1;
  }
  .tickets-button:active {
    border: solid vw(8) #ffffff;
  }
  .tickets-button:hover {
    background-image: linear-gradient(102deg, #202020 5%, #404040 97%);
  }
  .back-button {
    width: vw(620);
    height: vw(100);
    border-radius: vw(65);
    border: solid vw(8) #535353;
    background-color: #000;
    div {
      font-size: vw(29);
    }
    margin-bottom: vw(500);
    z-index: 1;
  }
  .back-button:active {
    border: solid vw(8) #aaaaaa;
  }
  .back-button:hover {
    background-color: #202020;
  }
  .tendril-overlay {
    position: absolute;
    top: vw(160);
    width: 100%;
    height: auto;
  }
}
.venomized-output-container.taller-photo {
  top: vw(200);
}
@media screen and (min-aspect-ratio: 1/1) {

  .venomized-output-container {
    top: vw(-160, $design-width-desktop);
    left: vw(558, $design-width-desktop);
    transform: translateX(-50%);
    width: fit-content;
    .venom-button {
      width: vw(420, $design-width-desktop);
      height: vw(90, $design-width-desktop);
      border-radius: vw(90, $design-width-desktop);
      border-width: vw(3, $design-width-desktop);
      div {
        font-size: vw(30, $design-width-desktop);
      }
    }
    .download-button {
      margin-bottom: vw(60, $design-width-desktop);
      border: solid vw(3, $design-width-desktop) #292929;
    }
    .download-button.animation-finished {
      border: solid vw(3, $design-width-desktop) #bf1f21;
    }
    .download-original {
      margin-bottom: vw(63, $design-width-desktop);
      .download-icon {
        width: vw(41, $design-width-desktop);
        margin-right: vw(15, $design-width-desktop);
      }
      .download-text {
        top: vw(-10, $design-width-desktop);
        font-size: vw(30, $design-width-desktop);
        text-decoration-thickness: vw(2, $design-width-desktop);
        text-underline-offset: vw(10, $design-width-desktop);
      }
    }
    .download-button.animation-finished:active {
      border: $button-outline-active solid vw(3, $design-width-desktop);
    }
    .share-text {
      position: absolute;
      width: vw(550, $design-width-desktop);
      top: vw(315, $design-width-desktop);
      height: vw(90, $design-width-desktop);
      left: vw(918, $design-width-desktop);
      text-align: center;
      transform: translateX(-50%);
      font-size: vw(30, $design-width-desktop);
      margin-bottom: 0;

      display: flex;
      justify-content: center;
      align-items: center;
    }
    .social-icons-body {
      position: absolute;
      width: vw(550, $design-width-desktop);
      top: vw(410, $design-width-desktop);
      left: vw(918, $design-width-desktop);
      transform: translateX(-50%);
      display: flex;
      flex-direction: row;
      justify-content: center;
      .social-icon {
        height: vw(75, $design-width-desktop);
        width: auto;
        margin-left: vw(26, $design-width-desktop);
        margin-right: vw(26, $design-width-desktop);
      }
    }
    .tickets-button {
      width: vw(420, $design-width-desktop);
      height: vw(90, $design-width-desktop);
      border-radius: vw(90, $design-width-desktop);
      box-shadow: 0 0 vw(86, $design-width-desktop) 0 rgba(191, 31, 33, 0.58);
      border: solid vw(3, $design-width-desktop) #afafaf;
      margin-bottom: vw(60, $design-width-desktop);
      div {
        font-size: vw(30, $design-width-desktop);
      }
    }
    .tickets-button:active {
      border: solid vw(3, $design-width-desktop) #ffffff;
    }
    .back-button {
      width: vw(420, $design-width-desktop);
      height: vw(80, $design-width-desktop);
      border-radius: vw(80, $design-width-desktop);
      border: solid vw(3, $design-width-desktop) #535353;
      div {
        font-size: vw(22, $design-width-desktop);
      }
      margin-bottom: 0;
    }
    .back-button:active {
      border: solid vw(3, $design-width-desktop) #aaaaaa;
    }
    .tendril-overlay {
      display: none;
    }
  }

  .venomized-output-container.taller-photo {
    top: vw(-160, $design-width-desktop);
  }
  .venomized-output-container.even-taller-photo {
    top: vw(-160, $design-width-desktop);
  }
}
</style>