<script setup>

import {ref, defineEmits, computed} from 'vue'
import {useStore} from "vuex";
import analytics from "@/js/analytics";

const store = useStore();

const props = defineProps({});

const locale = computed(() => store.getters.getLocale);

const changeLocale = function (locale) {
  store.dispatch("changeLocale", locale);
};

</script>

<template>
  <div class="LanguageToggle flex-center">
    <div class = "language-button" :class = "{'is-active': (locale == 'caen')}" @click = "changeLocale('caen')">ENGLISH</div>
    <div class = "language-button" :class = "{'is-active': (locale == 'cafr')}" @click = "changeLocale('cafr')">FRANÇAIS</div>
  </div>
</template>

<style scoped lang='scss'>
@import '@/sass/GlobalClasses';
@import '@/sass/Settings';

.LanguageToggle {
  position: relative;
  width: 100%;


  .language-button{
    font-size: 12px;
    font-family: Lato;
    font-weight: bold;
    color: white;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    margin-top: 0;
    pointer-events: auto;

    &.is-active{
      color: $language-toggle;
    }
  }
}

</style>