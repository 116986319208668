<script setup>

import {ref, useTemplateRef, defineEmits, onMounted, onUnmounted} from 'vue'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import audioControl from "@/js/audioControl";

const slides = ref([
  {
    'video': "assets/imgs/carousel_loop_01.mp4",
    'image': "assets/imgs/carousel_loop_01-still.jpg"
  },

  {
    'video': "assets/imgs/carousel_loop_02.mp4",
    'image': "assets/imgs/carousel_loop_02-still.jpg"
  },
  {
    'video': "assets/imgs/carousel_loop_03.mp4",
    'image': "assets/imgs/carousel_loop_03-still.jpg"
  }
]);

const areVideosLoaded = ref([
  true,
  true,
  true
]);

function playSound() {
  const mediaQuery = window.matchMedia('(min-aspect-ratio: 1/1)')
  if (!mediaQuery.matches) {
    audioControl.restartAudio("carousel");
  }
}

const carouselParent = useTemplateRef('carouselParent');

const video0 = useTemplateRef('video0')
const video1 = useTemplateRef('video1')
const video2 = useTemplateRef('video2')
const videos = [
    video0,
    video1,
    video2
]

let currentVideoDesktop = 0;

let currentlyPlayingVideo = null;

let desktopCarouselInterval;

let showCarousel = ref(false);


onMounted(function () {

  setTimeout(function () {
    //let currentSlide = carouselParent.value.getElementsByClassName("carousel__slide--active")[0];
    //let video = currentSlide.getElementsByTagName("video")[0];
    //video.play();
    //currentlyPlayingVideo = video;
  }, 200)

  desktopCarouselInterval = setInterval(function () {
    currentVideoDesktop = currentVideoDesktop + 1;
    if (currentVideoDesktop > 2)
      currentVideoDesktop = 0;
    videos[currentVideoDesktop].value.play();
  }, 3000)

  setTimeout(function () {

    showCarousel.value = true;
    setTimeout(function () {
      let currentSlide = carouselParent.value.getElementsByClassName("carousel__slide--active")[0];
      let video = currentSlide.getElementsByTagName("video")[0];
      video.addEventListener("canplaythrough", () => {
        video.play();
      })
    }, 200)
  }, 600)
  return;
  for(let i = 0; i < videos.length; i++){
    const num = i;
    videos[i].value.addEventListener("canplaythrough", () => {
      areVideosLoaded.value[num] = true;

      if(areVideosLoaded.value[0] && areVideosLoaded.value[1] && areVideosLoaded.value[2]){
        showCarousel.value = true;
        setTimeout(function () {
          let currentSlide = carouselParent.value.getElementsByClassName("carousel__slide--active")[0];
          let video = currentSlide.getElementsByTagName("video")[0];
          video.addEventListener("canplaythrough", () => {
            video.play();
          })
        }, 200)

      }
    });
  }


})
onUnmounted(function () {
  clearInterval(desktopCarouselInterval);
})

function stopVideo() {
  if (currentlyPlayingVideo)
  {
    currentlyPlayingVideo.currentTime = 0;
    currentlyPlayingVideo.pause();
  }
}
function playVideo() {
  setTimeout(function () {
    let currentSlide = carouselParent.value.getElementsByClassName("carousel__slide--active")[0];
    let video = currentSlide.getElementsByTagName("video")[0];
    video.play();
    currentlyPlayingVideo = video;
  }, 200)
}

const props = defineProps({});

</script>

<template>
  <div class="venomized-carousel"  ref="carouselParent">
    <transition name="fade">
      <img v-if="showCarousel" class="tendrals-behind-image-carrousel" src="../../public/assets/imgs/tendrals-behind-image-carrousel.png"/>
    </transition>
    <transition name="fade">
      <carousel v-if="showCarousel" @slide-start="stopVideo" @slide-end="playVideo" :items-to-show="1.3" :wrap-around="true" autoplay="5300"  transition="500" pauseAutoplayOnHover>
        <slide v-for="(slide, index) in slides" :key="slide">
          <div class = "carousel__item">
            <!--<img class="slide-image clip-path-2" :src="slide.image"/>-->
            <video class = "slide-image clip-path-2 video" :src = "slide.video + '#t=0.01'" :poster="slide.image"  muted playsinline/>
            <div class="border border-2"></div>
            <div class = "sparkle-button" ontouchstart @click.once="$emit('handle-continue')">
              <img class = "ai-star" src = "../../public/assets/imgs/ai-star.png"/>
            </div>
          </div>
        </slide>

        <template #addons>
          <pagination class = "pagination" />
        </template>
      </carousel>
    </transition>
    <div class="carousel-space-filler" v-if="!showCarousel">

    </div>

    <div class="static-display">
      <img class="tendrals-behind-image-carrousel" src="../../public/assets/imgs/carousel-background-tendrils.png"/>
      <div class = "static-carousel-item" :key="0">
        <!--<img class="slide-image" :src="slides[1].image"/>-->
        <video class = "slide-image" :src = "slides[1].video" :poster="slides[1].image" muted playsinline ref="video0"/>
        <div class="border"></div>
        <div class = "sparkle-button" ontouchstart @click.once="$emit('handle-continue')">
          <img class = "ai-star" src = "../../public/assets/imgs/ai-star.png"/>
        </div>
      </div>
      <div class = "static-carousel-item" :key="1">
        <!--<img class="slide-image" :src="slides[0].image"/>-->
        <video class = "slide-image"  :src = "slides[0].video" :poster="slides[0].image" muted playsinline ref="video1"/>
        <div class="border"></div>
        <div class = "sparkle-button" ontouchstart @click.once="$emit('handle-continue')">
          <img class = "ai-star" src = "../../public/assets/imgs/ai-star.png"/>
        </div>
      </div>
      <div class = "static-carousel-item" :key="2">
        <!--<img class="slide-image" :src="slides[2].image"/>-->
        <video class = "slide-image"  :src = "slides[2].video" :poster="slides[2].image" muted playsinline ref="video2"/>
        <div class="border"></div>
        <div class = "sparkle-button" ontouchstart @click.once="$emit('handle-continue')">
          <img class = "ai-star" src = "../../public/assets/imgs/ai-star.png"/>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped lang='scss'>
@import '@/sass/GlobalClasses';
@import '@/sass/Settings';

.venomized-carousel {
  position: relative;
  width: 100%;
  .static-display {
    display: none;
  }
}

.pagination{
  margin-top: vw(30);
}
.carousel-space-filler {
  margin-top: 40.1777777778vw;
  margin-bottom: 13.3333333333vw;
  height: vw(925);
}

.sparkle-button{
  position: absolute;
  width: vw(186);
  height: vw(186);
  border-radius: vw(186);
  border: $button-outline solid vw(9);
  //background-color: black;
  background-color: transparent;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;

  bottom: vw(50);
  left: 41%;
  pointer-events: auto;
  z-index: inherit;

  &:active{
    border: $button-outline-active solid vw(9);
  }
  .ai-star {
    width: vw(77);
  }
  &:hover {
    background-color: #220001;
  }
}
.carousel__slide--active {
  .sparkle-button {
    background-color: black;
    opacity: 1;
  }
}
.carousel__slide--sliding {
  .sparkle-button {
    transition: opacity 1s;
  }
}


.tendrals-behind-image-carrousel {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.2s;
}

.carousel__item {
  width: 100%;
  height: 77vw;
  border-radius: vw(24);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.border {
  @extend %absolute-fill;
  border: #6a6a6a solid 1px;
  border-radius: 0.9vw;
  box-sizing: border-box;
}
.border.border-2 {
  border-radius: 3.5vw;
}

.slide-image {
  @extend %absolute-fill;
  clip-path: inset(0% 0% 0% 0% round 0.9vw);
}
.clip-path-2 {
  clip-path: inset(0% 0% 0% 0% round 3.5vw);
}
.carousel__slide {
  //padding: vw(30);
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 1;
  transform: scale(0.8);
}



.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.0);
}

.carousel__slide--prev {
  opacity: 1;
  transform: scale(0.85) translateX(10vw);
  z-index: -1;
}

.carousel__slide--next {
  opacity: 1;
  transform: scale(0.85)  translateX(-10vw);
  z-index: -1;
}

@media screen and (min-aspect-ratio: 1/1) {
  .venomized-carousel {

    padding-top: vw(155, $design-width-desktop);
    padding-bottom: vw(246, $design-width-desktop);
    width: 100%;
    height: auto;
    pointer-events: none;
    .carousel-space-filler {
      margin-top: 0;
      margin-bottom: 0;
      height: 0;
    }
    .tendrals-behind-image-carrousel {
      display: none;
    }
    .carousel {
      display: none;
    }
    .static-display {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-left: vw(676, $design-width-desktop);
      width: fit-content;
      .tendrals-behind-image-carrousel {
        display: block;
        position: absolute;
        top: 50%;
        left: 48%;
        transform: translate(-50%, -50%);
        width: vw(1200, $design-width-desktop);
        height: vw(475, $design-width-desktop);
      }
      .static-carousel-item {
        position: relative;
        width: vw(400, $design-width-desktop);
        height: vw(400, $design-width-desktop);
        .sparkle-button{
          background-color: black;
          width: vw(90, $design-width-desktop);
          height: vw(90, $design-width-desktop);
          border-radius: vw(90, $design-width-desktop);
          border: $button-outline solid vw(5, $design-width-desktop);
          opacity: 1;

          bottom: vw(40, $design-width-desktop);

          .ai-star {
            width: vw(37, $design-width-desktop);
          }
        }
      }
      .static-carousel-item:nth-child(2) {
        left: 2vw;
        width: vw(380, $design-width-desktop);
        height: vw(380, $design-width-desktop);
        .sparkle-button {
          display: none;
        }
        z-index: -1;
      }
      .static-carousel-item:nth-child(4) {
        right: 2vw;
        width: vw(380, $design-width-desktop);
        height: vw(380, $design-width-desktop);
        .sparkle-button {
          display: none;
        }
        z-index: -1;
      }
    }
  }
}
</style>