<script setup>
import { ref, computed } from "vue";
import DebugFramerate from "./DebugFramerate.vue";
import DebugEvent from "./DebugEvent.vue";
import DebugConsole from "./DebugConsole.vue";
import DebugPermissions from "./DebugPermissions.vue";
import DebugStore from "./DebugStore.vue";
import DebugErrorScreens from "./DebugErrorScreens.vue";
import DebugSkipSteps from "@/components/debug_modules/DebugSkipSteps.vue";

const isDebugEnabled = ref(false);
const debugComponents = ref([
    DebugSkipSteps,
  DebugFramerate,
  DebugErrorScreens,
  DebugEvent,
  DebugConsole,
  DebugPermissions,
  DebugStore,
]);
const currentDebugComponent = ref(0);

const toggleDebug = () => {
  console.log("foired");
  isDebugEnabled.value = !isDebugEnabled.value;
};
const incrementDebugModule = (shiftAmount) => {
  let newComponentIndex = currentDebugComponent.value;
  newComponentIndex += shiftAmount;
  if (newComponentIndex >= debugComponents.value.length) {
    newComponentIndex %= debugComponents.value.length;
  } else if (newComponentIndex < 0) {
    newComponentIndex =
      debugComponents.value.length -
      Math.abs(newComponentIndex % debugComponents.value.length);
  }
  currentDebugComponent.value = newComponentIndex;
  console.log(debugComponents.value[currentDebugComponent.value].__name);
};

const getAppVersion = computed(() => {
  return process.env.VUE_APP_VERSION
    ? process.env.VUE_APP_VERSION
    : "Unknown Version";
});

const getAppDebugMode = computed(() => {
  return process.env.NODE_ENV == "development";
});
</script>

<template>
  <div class="debug-container" v-if="getAppDebugMode">
    <div class="debug-modules" v-if="isDebugEnabled">
      <component v-bind:is="debugComponents[currentDebugComponent]" />
      <div class="debug-module-controls">
        <div class="background" />
        <div class="left" v-on:click="incrementDebugModule(-1)" />
        <div class="name">
          {{ debugComponents[currentDebugComponent].__name }}
        </div>
        <div class="right" v-on:click="incrementDebugModule(1)" />
      </div>
    </div>
    <div class="debug-area">
      <div class="version-number">
        {{ getAppVersion }}
      </div>
      <div class="debug-button" v-on:click="toggleDebug" />
    </div>
  </div>
</template>
