<script setup>
import {inject} from "vue";

const translate = inject('translate')
</script>

<template>
  <header class="header">
    <div class="sonyheader">
      <div><a :href="translate('sony-pictures-url')" target="_blank"> <img src="../../public/assets/imgs/sp_logo.svg" alt="sony pictures logo"></a></div>
      <div><a href="https://www.sony.com" target="_blank"><img src="../../public/assets/imgs/sony.svg" alt="sony logo" class="sony_logo"></a></div>
    </div>
  </header>
</template>

<style scoped lang="scss">

</style>