import mediapipe from "@/js/mediapipe";
import axios from "axios";
import {store} from "@/main";

const venomizer = {
    toDataURL(image, callback, outputFormat) {

        let scaleFactor = 1.0;

        /*if(image.naturalWidth > image.naturalHeight){
            scaleFactor = 1024 / image.naturalWidth;
        } else {
            scaleFactor = 1024 / image.naturalHeight;
        }*/
        scaleFactor = 1024 / image.naturalWidth;

        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d');
        var dataURL;
        canvas.height = image.naturalHeight * scaleFactor;
        canvas.width = image.naturalWidth * scaleFactor;
        ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight,     // source rectangle
          0, 0, canvas.width, canvas.height);

        dataURL = canvas.toDataURL(outputFormat, 0.6);
        return dataURL;
    },
    async dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    },
    imagePromise(src) {
        const image = new Image();
        let promise = new Promise((resolve, reject) => {
            image.onload = () => {
                resolve(image);
            };
            image.onerror = reject;
        });

        image.src = src;
        return promise;
    },
    async cropImage(originalImage) {
        let maxAspectRatio = 1.5;
        let minAspectRatio = 0.5;
        let targetAspectRatio;
        let targetImageWidth;
        let targetImageHeight;
        let aspectRatio = originalImage.width / originalImage.height;
        if (aspectRatio <= maxAspectRatio && aspectRatio >= minAspectRatio)
            return aspectRatio;
        else
        {
            if (aspectRatio > maxAspectRatio)
            {
                targetAspectRatio = maxAspectRatio;
                targetImageHeight = originalImage.height;
                targetImageWidth = targetImageHeight * targetAspectRatio;
            }
            if (aspectRatio < minAspectRatio)
            {
                targetAspectRatio = minAspectRatio;
                targetImageWidth = originalImage.width;
                targetImageHeight = targetImageWidth * (1/targetAspectRatio);
            }

            const captureCanvas = document.createElement("canvas");
            const captureContext = captureCanvas.getContext("2d");

            captureCanvas.width = targetImageWidth;
            captureCanvas.height = targetImageHeight;
            captureContext.drawImage(originalImage, captureCanvas.width / 2 - originalImage.width / 2, captureCanvas.height / 2 - originalImage.height / 2, originalImage.width, originalImage.height);

            let dataURL = captureCanvas.toDataURL();
            store.commit("setUploadedPictureSrc", dataURL);
            return new Promise((resolve, reject) => {
                originalImage.onload = () => {
                    resolve(targetAspectRatio);
                };
                originalImage.onerror = reject;
            });
        }
    },
    async classify(image) {

        let humanDetected = false;
        let animalDetected = true;

        //let dataURL = this.toDataURL(image);
        //console.log(dataURL)

        try {
            // Wait for the detection to complete
            const detections = await mediapipe.objectDetector.detect(image);

            // Run your function here after detection is complete
            console.log(detections);
            let {hasFoundPerson, hasFoundAnimalSimple} = mediapipe.handleObjectDetections(detections);
            humanDetected = hasFoundPerson;
            animalDetected = hasFoundAnimalSimple;

            document.detections = detections


        } catch (error) {
            console.error("Error during object detection:", error);
        }

        try {
            const classificationResult = await mediapipe.imageClassifier.classify(image);
            // Write the predictions to a new paragraph element and add it to the DOM.
            const classifications = classificationResult.classifications;
            console.log("classifications")
            console.log(classifications)
            let {hasFoundAnimalComplex} = mediapipe.handleImageClassifications(classifications)
            animalDetected = animalDetected || hasFoundAnimalComplex;
        } catch (error) {
            console.error("Error during image./ classification:", error);
        }
        if (humanDetected)
            store.commit("setFailScreen", "human")
        else if (!animalDetected)
            store.commit("setFailScreen", "no-animal")

        if (store.getters.getFailScreen != '') {
            store.commit("setLoading", false);
            store.commit("setUploadedPictureSrc", "");
        }
    },
    async venomizeTest(originalImage) {
        const [fpo] = await Promise.all([this.imagePromise("assets/imgs/fpo.png")]);
        const captureCanvas = document.createElement("canvas");
        const captureContext = captureCanvas.getContext("2d");
        captureCanvas.width = originalImage.width;
        captureCanvas.height = originalImage.height;
        captureContext.drawImage(originalImage, 0, 0, captureCanvas.width, captureCanvas.height);
        captureContext.fillStyle = "rgba(0, 0, 0, 0.8)";
        captureContext.fillRect(0, 0, captureCanvas.width, captureCanvas.height);
        captureContext.drawImage(fpo,captureCanvas.width / 2 - 54, captureCanvas.height / 2 - 23, 108, 46);

        return captureCanvas.toDataURL();
    },
    async venomize(file, image) {
        let imageURL = null;
        let dataURL = this.toDataURL(image, ()=>{}, "image/jpeg");
        let fileName = `image-${this.makeid(8)}-${(new Date()).getTime()}.jpg`;
        let imageFile = await this.dataURLtoFile(dataURL, fileName);
        const form = new FormData();
        form.append('image', imageFile);

        try {
            //Get Token
            const imageUploadResponse = await axios.post(
                store.getters.backendLink,
                form,
              {
                  timeout: 60000
              }
            );

            imageURL = imageUploadResponse.data;
        } catch (error) {
            imageURL = ""; //This just makes it fail properly
            console.log(error);
        }
        return imageURL;
    },
    makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    },
    async addWatermark(originalImage) {
        //originalImage is already loaded at this point
        let imageAspectRatio = originalImage.width / originalImage.height;
        let watermarkSrc;
        let assumedWidth = 801;
        if (imageAspectRatio < 1)
            watermarkSrc = "assets/imgs/symbiote-photo-frame-background.png"
        else{
            watermarkSrc = "assets/imgs/symbiote-photo-frame-background-wide.png"
            assumedWidth = 1302;
        }
        let bottomLeftSrc = `assets/imgs/symbiote-photo-frame/movie-${store.getters.getLocale}.png`;
        let bottomRightSrc = `assets/imgs/symbiote-photo-frame/date-${store.getters.getLocale}.png`;
        const [watermark, bottomLeft, bottomRight] = await Promise.all([this.imagePromise(watermarkSrc), this.imagePromise(bottomLeftSrc), this.imagePromise(bottomRightSrc)]);
        let watermarkAspectRatio = watermark.width / watermark.height;

        let bottomLeftAspectRatio = bottomLeft.width / bottomLeft.height;
        let bottomLeftAbsoluteRatio = bottomLeft.width / assumedWidth;

        let bottomRightAspectRatio = bottomRight.width / bottomRight.height;
        let bottomRightAbsoluteRatio = bottomRight.width / assumedWidth;

        let calculatedLeftSize = {
            offsetX: 19 * bottomLeftAbsoluteRatio,
            offsetY: 7 * bottomLeftAbsoluteRatio / bottomLeftAspectRatio,
            width: originalImage.width * bottomLeftAbsoluteRatio,
            height: originalImage.width * bottomLeftAbsoluteRatio / bottomLeftAspectRatio
        }

        let calculatedRightSize = {
            offsetX: bottomRightAbsoluteRatio,
            offsetY: bottomRightAbsoluteRatio / bottomRightAspectRatio,
            width: originalImage.width * bottomRightAbsoluteRatio,
            height: originalImage.width * bottomRightAbsoluteRatio / bottomRightAspectRatio
        }


        const captureCanvas = document.createElement("canvas");
        const captureContext = captureCanvas.getContext("2d");
        captureCanvas.width = originalImage.width;
        captureCanvas.height = originalImage.height;
        captureContext.drawImage(originalImage, 0, 0, captureCanvas.width, captureCanvas.height);
        captureContext.drawImage(watermark, 0, captureCanvas.height - (captureCanvas.width * (1 / watermarkAspectRatio)), captureCanvas.width, captureCanvas.width * (1 / watermarkAspectRatio));
        captureContext.drawImage(bottomLeft,
          calculatedLeftSize.offsetX ,
          captureCanvas.height - calculatedLeftSize.height - calculatedLeftSize.offsetY,
          calculatedLeftSize.width,
          calculatedLeftSize.height);

        captureContext.drawImage(bottomRight,
          captureCanvas.width - calculatedRightSize.width - calculatedRightSize.offsetX,
          captureCanvas.height - calculatedRightSize.height - calculatedRightSize.offsetY,
          calculatedRightSize.width,
          calculatedRightSize.height);

        let dataURL = captureCanvas.toDataURL();
        store.commit("setDownloadablePictureSrc", dataURL);
        return dataURL;
    }
}
export default venomizer;