<script setup>

import {inject, onMounted} from 'vue'
import analytics from "@/js/analytics";
const translate = inject('translate')

onMounted(() => {
  analytics.setPage("loading screen")
});
</script>

<template>
  <div class="loading-container">
    <video class="video" src="assets/imgs/loading.mp4" autoplay loop playsinline></video>
    <img :src="translate('processing-main-message-image')" class="text-header">
    <div class="venom-text-body" v-html="translate('processing-secondary-message')"></div>
  </div>
</template>

<style scoped lang="scss">
@import '../sass/GlobalClasses';
@import '../sass/Settings';

.loading-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 10;
  .video {
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -50%);
    width: vh(924);
    height: auto;
  }
  .text-header {
    position: absolute;
    width: vh(800);
    height: auto;
    bottom: vh(430);
    left: 50%;
    transform: translateX(-47%);
  }
  .venom-text-body {
    position: absolute;
    width: vh(890);
    bottom: vh(356);
    left: 50%;
    transform: translateX(-50%);
    line-height: 0.9;
    font-size: vh(52);
    color: #ccc;
  }
}
</style>