<script setup>
import { ref, onMounted } from "vue";
import debugConsole from "../../js/interceptConsole.js";
import {inject} from 'vue';
const vw = inject('vw');
const log = ref(null);
const scroll = ref(null);

onMounted(() => {
  log.value.innerHTML = debugConsole.getFullList("<br /><br />");
  scroll.value.scrollTo(0, scroll.value.scrollHeight);
  document.querySelector("body").addEventListener("debug-intercept", () => {
    //if (!log["debug-log"]) return;
    //log["debug-log"].innerHTML += debugConsole.getLastLog() + "<br />";
  });
});

const downloadLog = () => {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," +
      encodeURIComponent(debugConsole.getFullList())
  );
  element.setAttribute("download", "greatest_debug_ever.txt");

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};
</script>

<template>
  <div class="module-container">
    <div class="background" />
    <div class="debug-text-scroll" ref="scroll">
      <div class="debug-text" ref="log" />
    </div>
    <div class="download-area">
      <div class="download-button">
        <div class="text" v-on:click="downloadLog">Download Log</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../sass/GlobalClasses";
@import "../../sass/Settings";

.module-container {
  @extend %absolute-fill;

  .background {
    @extend %absolute-fill;
    background-color: black;
    opacity: 0.3;
  }

  .debug-text-scroll {
    width: 100%;
    height: 80vh;
    overflow: scroll;
    position: absolute;
    bottom: 20%;
    left: 0;
    pointer-events: auto;
  }
  .debug-text {
    color: white;
    text-align: left;
    font-size: 4vw;
    padding: vw(10);
  }

  .download-area {
    width: 100%;
    position: absolute;
    bottom: vw(10);
    display: flex;
    justify-content: center;

    .download-button {
      width: 50%;
      padding: vw(10);
      pointer-events: auto;
      position: relative;
      background-color: white;

      .text {
        color: black;
        display: flex;
        text-align: center;

        justify-content: center;
        position: relative;
      }
    }
  }
}
</style>
