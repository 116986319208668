<script setup>
import {ref, computed, onMounted, useTemplateRef} from "vue";
import { useStore } from "vuex";
import LandingView from "./views/LandingWrapper.vue";
import audioControl from "./js/audioControl.js";
import ScrollingBackground from "@/components/ScrollingBackground.vue";
import VenomizerCarousel from "@/components/VenomizerCarousel.vue";
import PhotoInputWrapper from "@/views/PhotoInputWrapper.vue";
import PhotoPreviewWrapper from "@/views/PhotoPreviewWrapper.vue";
import VenomizedOutputWrapper from "@/views/VenomizedOutputWrapper.vue";
import UploadButton from "@/views/UploadButton.vue";
import FailWrapper from "@/views/fail_modules/FailWrapper.vue";
import DebugMenu from "@/components/debug_modules/DebugMenu.vue";
import venomizer from "@/js/venomizer";
import LoadingWrapper from "@/views/LoadingWrapper.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";

import { inject } from 'vue'
import analytics from "@/js/analytics";
const translate = inject('translate')

// grab state
const store = useStore();

const state = computed(() => store.getters.getState);
const failScreen = computed(() => store.getters.getFailScreen);
const loading = computed(() => store.getters.getLoading);
const locale = computed(() => store.getters.getLocale);

const uploadedPictureSrc = computed(() => store.getters.getUploadedPictureSrc);
const venomizedPictureSrc = computed(() => store.getters.getVenomizedPictureSrc);
const downloadablePictureSrc = computed(() => store.getters.getDownloadablePictureSrc);
const photoAspectRatio = computed(() => store.getters.photoAspectRatio);

const uploadedPictureImage = useTemplateRef('uploaded-picture');
const venomizedPictureImage = useTemplateRef('venomized-picture');
const downloadablePictureImage = useTemplateRef('downloadable-picture');

const scrollPercentage = ref(0.0);

let venomizedRevealed = ref(false);
let swipAngle = ref(0);
let swipLength = ref(0);
function handleContinueAI() {
  handleContinue('venomize_my_pet_ai_button')
}
function handleContinueLetsGo() {
  handleContinue('venomize_my_pet_lets_go')
}
function handleContinue(analyticsEvent) {
  audioControl.restartAudio("click");
  analytics.analyticsMethod(analyticsEvent);
  setTimeout(function () {
    store.commit("setState", 'photo-input');
    //store.commit("setFailScreen", "over-1mil")
  }, 100)
}

async function handleConfirm() {

  audioControl.restartAudio("click");
  analytics.analyticsMethod('venomize_my_pet_looks_great');
  store.commit("setLoading", true);
  await venomizer.classify(uploadedPictureImage.value);
  console.log("classified")
  let imageURL = await venomizer.venomize(store.getters.uploadedImageFile, uploadedPictureImage.value);
  console.log(`image url ${imageURL == ""}`)
  if(imageURL == ""){
    console.log('setting fail screen')
    store.commit('setFailScreen', 'photo-issue');
    store.commit("setLoading", false);
  } else {
    store.commit('setVenomizedPictureSrc', imageURL);
  }
}

function playVenomizeAnimation() {
  audioControl.restartAudio("reveal");
  if (venomizedRevealed.value)
  {
    venomizedRevealed.value = false;
  }
  else
  {
    analytics.analyticsMethod('venomize_my_pet_tap_to_reveal');
    venomizedRevealed.value = true;
  }
}

onMounted(() => {
  window.addEventListener("scroll", () => {

    let scrollMaxHeight = Math.max( document.body.scrollHeight, document.body.offsetHeight,
        document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );
    let screenHeight = window.innerHeight;
    let scrollHeight = scrollMaxHeight - screenHeight;
    scrollPercentage.value = document.scrollingElement.scrollTop/scrollHeight;

  });

  venomizedPictureImage.value.onload = function () {
    venomizer.addWatermark(venomizedPictureImage.value)
  };
  downloadablePictureImage.value.onload = function () {
    venomizedRevealed.value = false;
    store.commit("setState", "venomized-output")
    store.commit("setLoading", false);
  };
});
</script>

<template>
  <div class="venom-app">
    <Header></Header>
    <ScrollingBackground :scroll-amount="scrollPercentage" desktop-src="assets/imgs/desktop-header-bkgnd.png" src = "assets/imgs/sky-background.png" :height-scale="1.2" />

    <img class = "title-image" :src="`./assets/imgs/venomize-my-pet-logo/${locale}.png`" :class="{'desktop-position-2': state!='landing', 'desktop-fail': failScreen!=''}" />
    <div class="fail-offset" v-if="failScreen != ''"></div>
    <div class="above-content" v-show="failScreen == ''">
      <VenomizerCarousel
          v-if="state == 'landing'"
          key = "venomizer-carousel"
          @handle-continue="handleContinueAI"
      />
      <div class="landing-desktop" v-if="state == 'landing'">
        <div class = "venom-text-body" v-html="translate('main-landing-instruction')"></div>
      </div>
      <UploadButton
          v-else-if="state == 'photo-input'"
      />
      <div class="picture-container" @click="playVenomizeAnimation" v-show="state == 'photo-confirm' || state == 'venomized-output'" :class="{'taller-photo': photoAspectRatio < 1, 'even-taller-photo': photoAspectRatio < 0.65, 'clickable': state == 'venomized-output'}">
        <img ref="uploaded-picture" id = "uploaded-picture" class="uploaded-picture" crossOrigin="anonymous" :class="{'taller-photo': photoAspectRatio < 1, 'even-taller-photo': photoAspectRatio < 0.65}" v-show="state == 'photo-confirm' || state == 'venomized-output'" v-bind:src="uploadedPictureSrc"/>
        <!-- Photo Edge for Output Screen -->
        <img src="../public/assets/imgs/venom-photo-edge-bottom-left.png" class="venom-photo-edge bottom-left" v-show="state == 'venomized-output'">
        <img src="../public/assets/imgs/venom-photo-edge-bottom-right.png" class="venom-photo-edge bottom-right" v-show="state == 'venomized-output'">
        <img src="../public/assets/imgs/venom-photo-edge-top-left.png" class="venom-photo-edge top-left" v-show="state == 'venomized-output'">
        <!-- -->
        <!-- Photo Edge for Output Screen -->
        <!-- Photo Instructions -->
        <div class="venom-text-body picture-instructions" v-html="translate('output-reveal-prompt')"  v-show="state == 'venomized-output'"></div>
        <img src="../public/assets/imgs/tap-hand-solid.png" class="tap-hand-solid" v-show="state == 'venomized-output'">
        <div class="venom-text-body picture-instructions-2" v-html="translate('output-reveal-prompt-2')"  v-show="state == 'venomized-output'"></div>

        <!--
        <transition name="venomize-swip">
          <img src="../public/assets/imgs/venomized-results-swip.png" v-bind:style="{'transform': 'translate(-50%, -50%) rotate(-'+swipAngle+'deg)', 'width': swipLength+'px'}" class="venomized-results-swip" v-show="state == 'venomized-output' && venomizedRevealed">
        </transition>
        -->

        <img ref="venomized-picture" class="venomized-picture" crossOrigin="anonymous" style="display: none" v-bind:src="venomizedPictureSrc"/>
        <transition name="venomize">
          <img ref="downloadable-picture" class="downloadable-picture" crossOrigin="anonymous" :class="{'taller-photo': photoAspectRatio < 1, 'even-taller-photo': photoAspectRatio < 0.65}" v-show="state == 'venomized-output' && venomizedRevealed" v-bind:src="downloadablePictureSrc"/>
        </transition>
      </div>
    </div>

    <div class="below-content">
      <img class = "top-background-image" src="../public/assets/imgs/tendrils-header-break.png"/>
      <img class = "top-background-image-desktop" src="../public/assets/imgs/tendrils-header-desktop.png">

        <FailWrapper v-if="failScreen != ''"/>
        <LandingView
            v-else-if="state == 'landing'"
            @handle-continue="handleContinueLetsGo"
        />
        <PhotoInputWrapper
            v-else-if="state == 'photo-input'"
        />
        <PhotoPreviewWrapper
            v-else-if="state == 'photo-confirm'"
            @handle-confirm="handleConfirm"
            :class="{'taller-photo': photoAspectRatio < 1}"
        />
        <VenomizedOutputWrapper
            v-else-if="state == 'venomized-output'"
            :class="{'taller-photo': photoAspectRatio < 1}"
            :animation-finished="venomizedRevealed"
        />
    </div>
    <Footer></Footer>
    <LoadingWrapper v-if="loading"></LoadingWrapper>
    <DebugMenu></DebugMenu>
  </div>
</template>

<style lang="scss">

@import './sass/GlobalClasses';
@import './sass/Settings';

.venom-app {
  .title-image {
    position: absolute;
    top: vw(104);
    width: vw(496);
    height: auto;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }

  .fail-offset {
    height: vw(290);
  }
  .above-content {
    position: relative;
    z-index: 5;

    .carousel {

      width: 100%;
      margin-top: vw(452);
      margin-bottom: vw(150);
    }
    .landing-desktop {
      display: none;
    }
    .upload-button-container {
      margin-top: vw(532);
      margin-bottom: vw(390);
    }
    .picture-container {
      position: relative;
      margin-top: vw(572);
      top: vw(-100);
      width: fit-content;
      height: fit-content;
      overflow: hidden;
      pointer-events: none;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0 0 vw(19) vw(4) rgba(0,0,0,0.75);

      .picture {
        height: auto;
        background-color: #fff;
        width: 89vw;
        border: solid vw(30) #fff;
        box-sizing: border-box;
      }
      .picture.taller-photo {
        width: 66vw;
      }
      .picture.even-taller-photo {
        width: 57vw;
      }
      .uploaded-picture {
        @extend .picture;
      }
      .downloadable-picture {
        @extend .picture;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: #000;
        border: solid 1px #aaa;
        //animation: 1.6s cubic-bezier(.24,.55,.5,.8) wipe-in-top-left both;
      }

      .venom-photo-edge {
        position: absolute;
        z-index: 1;
        height: auto;
      }
      .venom-photo-edge.bottom-left {
        bottom: 0.3vw;
        left: 0;
        width: vw(434);
      }
      .venom-photo-edge.bottom-right {
        bottom: 0.3vw;
        right: 0;
        width: vw(242);
      }
      .venom-photo-edge.top-left {
        top: 0;
        left: 0;
        width: vw(568);
      }
      .venom-text-body {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: vw(52);
        text-shadow: 0 0 vw(10) #000;
        font-weight: bold;
        z-index: 2;
        color: #ffffff;
      }
      .picture-instructions {
        width: vw(500);
        top: vw(50);
      }
      .picture-instructions-2 {
        width: vw(500);
        bottom: vw(50);
      }
      .tap-hand-solid {
        width: vw(130);
        height: auto;

        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: vw(126);

        z-index: 2;
      }
      .venomized-results-swip {
        position: absolute;
        height: auto;
        animation: 1.6s cubic-bezier(.24,.55,.5,.8) swip both;
        z-index: 10;
      }
    }
    .picture-container.clickable {
      pointer-events: all;
    }
    .picture-container.taller-photo {
      top: vw(100);
      margin-top: vw(372);
    }
    .picture-container.even-taller-photo {
      top: vw(100);
      margin-top: vw(372);
    }

    .venomize-enter-active {
      animation: 1.6s cubic-bezier(.24,.55,.5,.8) wipe-in-top-left both;
    }
    .venomize-leave-active {
      animation: 1.6s cubic-bezier(.24,.55,.5,.8) wipe-in-top-left both reverse;
    }
    @keyframes wipe-in-top-left {
      0% {
        clip-path: polygon(150% 50%, 150% 50%, 50% 150%, 50% 150%);
      }
      80% {
        clip-path: polygon(50% -50%, 150% 50%, 50% 150%, -50% 50%);
      }
      100% {
        clip-path: polygon(50% -50%, 150% 50%, 50% 150%, -50% 50%);
      }
    }
    .venomize-swip-enter-active {
      animation: 1.6s cubic-bezier(.24,.55,.5,.8) swip both;
    }
    .venomize-swip-leave-active {
      animation: 1.6s cubic-bezier(.24,.55,.5,.8) swip both reverse;
    }
    @keyframes swip {
      0% {
        top: 100%;
        left: 100%;
        opacity: 1;
      }
      80% {
        top: 0;
        left: 0;
        opacity: 1;
      }
      100% {
        top: -8%;
        left: -8%;
        opacity: 0;
      }
    }

  }
  .below-content {
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: #050608;
    min-height: 50vh;
    .top-background-image {
      width: 100%;
      height: auto;
      position: absolute;
      top: -18.5vw;
      left: 0;
    }
    .top-background-image-desktop {
      width: 100%;
      height: auto;
      position: absolute;
      top: -18.5vw;
      left: 0;
      display: none;
    }

  }
}
@media screen and (min-aspect-ratio: 1/1) {
  .venom-app {
    .fail-offset {
      height: vw(220, $design-width-desktop);
    }
    .title-image {
      position: absolute;
      top: vw(96, $design-width-desktop);
      width: vw(436, $design-width-desktop);
      height: auto;
      left: vw(367, $design-width-desktop);
      z-index: 10;
    }
    .title-image.desktop-position-2 {
      top: vw(130, $design-width-desktop);
      left: vw(577, $design-width-desktop);
    }
    .title-image.desktop-fail {
      top: vw(130, $design-width-desktop);
      left: vw(577, $design-width-desktop);
    }
    .above-content {
      pointer-events: none;
      .landing-desktop {
        display: block;
        .venom-text-body {
          position: absolute;
          font-size: vw(30, $design-width-desktop);
          left: vw(99, $design-width-desktop);
          top: vw(390, $design-width-desktop);
          width: vw(522, $design-width-desktop);
          text-shadow: 0 0 13px #000;
        }
      }
      .upload-button-container {
        margin-top: 34.5vw;
        margin-bottom: vw(-50, $design-width-desktop);
        width: fit-content;
      }

      .picture-container {
        margin-top: 6.2708333333vw;
        top: vw(80, $design-width-desktop);
        left: vw(1269, $design-width-desktop);
        transform: translateX(-50%);
        cursor: pointer;
        box-shadow: 0 0 vw(19, $design-width-desktop) vw(4, $design-width-desktop) rgba(0, 0, 0, 0.75);

        .picture {
          width: 42vw;
          border: solid vw(20, $design-width-desktop) #fff;
        }
        .downloadable-picture {
          border: solid 2px #aaa;
        }

        .picture.taller-photo {
          width: 25vw;
        }
        .picture.even-taller-photo {
          width: 22vw;
        }

        .venom-photo-edge.bottom-left {
          width: vw(434/2, $design-width-desktop);
        }
        .venom-photo-edge.bottom-right {
          width: vw(242/2, $design-width-desktop);
        }
        .venom-photo-edge.top-left {
          width: vw(568/2, $design-width-desktop);
        }

        .venom-text-body {
          font-size: vw(52/1.5, $design-width-desktop);
          text-shadow: 0 0 vw(10/1.5, $design-width-desktop) #000;
        }
        .picture-instructions {
          width: vw(500/1.5, $design-width-desktop);
          top: vw(50/1.5, $design-width-desktop);
        }
        .picture-instructions-2 {
          width: vw(500/1.5, $design-width-desktop);
          bottom: vw(60/1.5, $design-width-desktop);
        }
        .tap-hand-solid {
          width: vw(130/1.5, $design-width-desktop);
          bottom: vw(136/1.5, $design-width-desktop);
        }
      }
      .picture-container.taller-photo {
        top: vw(143, $design-width-desktop);
        margin-top: vw(40, $design-width-desktop);
      }
      .picture-container.even-taller-photo {
        top: vw(143, $design-width-desktop);
        margin-top: vw(40, $design-width-desktop);
      }
    }
    .below-content {
      background-color: #000000;
      min-height: 50vh;
      .top-background-image {
        display: none;
      }
      .top-background-image-desktop {
        display: block;
        top: -20.5vw;
      }

    }
  }
}
</style>
