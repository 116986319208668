<script setup>
import { onUnmounted, onMounted, ref, inject, computed } from "vue";
import LanguageToggle from "@/components/LanguageToggle";
import {useStore} from "vuex";

const store = useStore();

const translate = inject('translate')

let footeropen = ref(true);

const toggle = () => {
  console.log("toggle")
  footeropen.value = !footeropen.value
}

const getLink = (textKey, linkKey) => {
  let text = translate(textKey);
  let link = translate(linkKey);

  return `<a href='${link}' target="_blank">${text}</a>`;
};

const linkExists = (textKey) => {
  let text = translate(textKey);

  return text != textKey;
};

const locale = computed(() => store.getters.getLocale);

onMounted(() => {
  footeropen.value = true;
  setTimeout(function () {
    footeropen.value = false;
  }, 1000)
});
</script>

<template>
  <footer class="footer sony-footer">
    <div class="main-footer">
      <LanguageToggle v-if = "locale == 'caen' || locale == 'cafr'"  />
      <div class="footer-links">
        <span v-if = "linkExists('privacy-policy-text')" v-html = "getLink('privacy-policy-text','privacy-policy-url')"></span>

        <span v-if = "linkExists('ca-privacy-policy-text')" v-html = "getLink('ca-privacy-policy-text','ca-privacy-policy-url')"></span>

        <span v-if = "linkExists('terms-and-conditions-text')" v-html = "getLink('terms-and-conditions-text','terms-and-conditions-url')"></span>

        <span v-if = "linkExists('ad-choices-text')" v-html = "getLink('ad-choices-text','ad-choices-url')"></span>

        <a v-if = "linkExists('your-privacy-choices-text')" :href="translate('your-privacy-choices-url')" target="_blank" class="personal_info">{{translate('your-privacy-choices-text')}} <img src="../../public/assets/imgs/Your_Privacy_Choices.png" alt="your privacy choices logo"></a>

        <br class="line-break">

        <a v-if = "linkExists('cookie-consent-tool-text')" href="#" onclick="OneTrust.ToggleInfoDisplay();event.preventDefault();" v-html = "translate('cookie-consent-tool-text')"></a>

        <a v-if = "linkExists('credits-drawer')" id="btnCredits" @click="toggle" v-html = "translate('credits-drawer')"></a>
      </div>
      <div class="copyright" v-html = "translate('legal-line-1')"></div>
    </div>
    <div id="containerCredits" v-bind:class="{'open': footeropen}">
      <div class="footerSeparator"></div>
      <div id="closeCredits" @click="toggle"></div>
      <div class="bugs">
        <img src="../../public/assets/imgs/generative-ai-logo.png">
      </div>
      <div class="bugs">
        <img src="../../public/assets/imgs/marvel-logo.png">
        <img src="../../public/assets/imgs/columbia-pictures-logo.png">
      </div>
      <div class="footer-links">

        <span v-if = "linkExists('sony-pictures-text')" v-html = "getLink('sony-pictures-text','sony-pictures-url')"></span>

        <span v-if = "linkExists('film-ratings-text')" v-html = "getLink('film-ratings-text','film-ratings-url')"></span>

        <span v-if = "linkExists('mpaa-text')" v-html = "getLink('mpaa-text','mpaa-url')"></span>

        <span v-if = "linkExists('privacy-policy-text')" v-html = "getLink('privacy-policy-text','privacy-policy-url')"></span>

        <span v-if = "linkExists('ca-privacy-policy-text')" v-html = "getLink('ca-privacy-policy-text','ca-privacy-policy-url')"></span>

        <span v-if = "linkExists('terms-and-conditions-text')" v-html = "getLink('terms-and-conditions-text','terms-and-conditions-url')"></span>

        <span v-if = "linkExists('ad-choices-text')" v-html = "getLink('ad-choices-text','ad-choices-url')"></span>

        <span v-if = "linkExists('your-privacy-choices-text')" v-html = "getLink('your-privacy-choices-text','your-privacy-choices-url')"></span>

        <br class="line-break">

        <a  v-if = "linkExists('cookie-consent-tool-text')" href="#" onclick="OneTrust.ToggleInfoDisplay();" v-html = "translate('cookie-consent-tool-text')"></a>
      </div>
      <div class="copyright" v-html = "translate('legal-line-1')"></div>
    </div>
  </footer>
</template>

<style scoped lang="scss">

</style>