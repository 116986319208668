// Figured I would put all the pga specific store variables in one place

export const failPermissionsStore = {
  state: {
    hasGyro: true,
    cameraPermissionsRequested: false,
    isCameraPermissionsFailed: false,
    isCameraPermissionsCancelled: false,
    isMotionPermissionsRequested: false,
    isPermissionFinished: false,
    appExitableFailed: false,
    androidUnsupported: false,
    upgradeIOS: false,
    wasCameraAutoRejected: false,
    errorScreenOverride: "",
  },
  getters: {
    getGyro: (state) => {
      return state.hasGyro;
    },
    getCameraPermissionsRequested: (state) => {
      return state.cameraPermissionsRequested;
    },
    getCameraPermissionsFailed: (state) => {
      return state.isCameraPermissionsFailed;
    },
    getCameraPermissionsCancelled: (state) => {
      return state.isCameraPermissionsCancelled;
    },
    getMotionPermissionsRequested: (state) => {
      return state.isMotionPermissionsRequested;
    },
    isPermissionFinished: (state) => {
      return state.isPermissionFinished;
    },
    appExitableFailed: (state) => {
      return state.appExitableFailed;
    },
    getAndroidUnsupported: (state) => {
      return state.androidUnsupported;
    },
    getUpgradeIOS: (state) => {
      return state.upgradeIOS;
    },
    getWasCameraAutoRejected: (state) => {
      return state.wasCameraAutoRejected;
    },
    getErrorScreenOverride: (state) => {
      return state.errorScreenOverride;
    },
  },
  mutations: {
    setGyro(state, target) {
      state.hasGyro = target;
    },
    setCameraPermissionsRequested(state, target) {
      state.cameraPermissionsRequested = target;
    },
    setCameraPermissionsFailed(state, target) {
      state.isCameraPermissionsFailed = target;
    },
    setCameraPermissionsCancelled(state, target) {
      state.isCameraPermissionsCancelled = target;
    },
    setMotionPermissionsRequested(state, target) {
      state.isMotionPermissionsRequested = target;
    },
    isPermissionFinished(state, target) {
      state.isPermissionFinished = target;
    },
    appExitableFailed(state, isFailed) {
      state.appExitableFailed = isFailed;
    },
    setAndroidUnsupported(state, target) {
      state.androidUnsupported = target;
    },
    setUpgradeIOS(state, target) {
      state.upgradeIOS = target;
    },
    setWasCameraAutoRejected(state, target) {
      state.wasCameraAutoRejected = target;
    },
    setErrorScreenOverride(state, target) {
      state.errorScreenOverride = target;
    },
  },
  actions: {

  },
};
