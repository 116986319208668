<script setup>
import { ref } from "vue";
import { inject } from 'vue';
const translate = inject('translate');
const vw = inject('vw');
const queryName = ref(null);
const eventName = ref(null);
const fireDebugEvent = () => {
  document
    .querySelector(queryName.value["query_name"].value)
    .dispatchEvent(new Event(eventName.value["event_name"].value));
};
</script>

<template>
  <div class="module-container">
    <input
      type="text"
      ref="query_name"
      value="body"
      class="event-query-input"
    />
    <input type="text" ref="event_name" class="event-name-input" />
    <div class="input-control-area">
      <div class="input-button" v-on:click="fireDebugEvent">Fire Event</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../sass/GlobalClasses";
@import "../../sass/Settings";

.module-container {
  @extend %absolute-fill;

  .event-name-input {
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translate(-50%, 0);
    pointer-events: auto;
    width: 80%;
  }

  .event-query-input {
    position: absolute;
    left: 50%;
    top: 15%;
    transform: translate(-50%, 0);
    pointer-events: auto;
    width: 80%;
  }

  .input-control-area {
    position: absolute;
    left: 0%;
    top: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    height: vw(100) !important;

    .input-button {
      background-color: white;
      position: relative;
      width: 50%;
      display: flex;
      height: 100%;
      justify-content: center;
      pointer-events: auto;
    }
  }
}
</style>
