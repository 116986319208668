<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
const store = useStore();


const screens = ref([
  {
    display: "Skip to End",
    original: "assets/imgs/cat.jpg",
    venomized: "assets/imgs/venomized-pet-test.jpg",
    state: "photo-confirm"
  },
  {
    display: "Loading",
    loading: true
  }
]);

const setErrorScreen = (value) => {

  if (value.state)
    store.commit("setState", value.state);
  if (value.original)
    store.commit("setUploadedPictureSrc", value.original);
  if (value.venomized)
    store.commit("setVenomizedPictureSrc", value.venomized);
  if (value.loading)
    store.commit("setLoading", value.loading);
};
</script>

<template>
  <div class="module-container">
    <div class="debug-screen-area">
      <div
        class="screen-button"
        v-for="screen in screens"
        v-bind:key="screen"
        v-on:click="setErrorScreen(screen)"
      >
        <div>
          {{ screen.display }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../sass/GlobalClasses";
@import "../../sass/Settings";

.module-container {
  @extend %absolute-fill;

  .debug-screen-area {
    display: flex;
    flex-direction: column;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;

    background-color: rgba(0, 0, 0, 0.3);

    padding-top: 4vw;
    padding-bottom: 4vw;

    .screen-button {
      font-size: 5vw;
      color: black;

      margin-bottom: 3vw;

      display: flex;
      justify-content: center;
      align-items: center;
      height: 6vw;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      padding-left: 2vw;
      padding-right: 2vw;

      position: relative;

      background-color: white;
      pointer-events: auto;
    }
  }
}
</style>
