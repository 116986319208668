// Figured I would put all the pga specific store variables in one place

export const appStateStore = {
  state: {
    state: "landing",
    //States:
    //  -- landing
    //  -- photo-input
    //  -- photo-confirm
    //  -- venomized-output
    failScreen: "",
    //Fail Screens:
    //  -- no-animal
    //  -- human
    //  -- photo-issue
    //  -- (anything else gives the generic error)

    loading: false,
    backendLink: "",

    uploadedPictureSrc: "",
    venomizedPictureSrc: "",
    downloadablePictureSrc: "",
    uploadedImageFile: "",

    cookiesAccepted: false,
    photoAspectRatio: 1
  },
  getters: {
    getState: (state) => {
      return state.state;
    },
    getFailScreen: (state) => {
      return state.failScreen;
    },
    getLoading: (state) => {
      return state.loading;
    },
    backendLink: (state) => {
      return state.backendLink;
    },
    getUploadedPictureSrc: (state) => {
      return state.uploadedPictureSrc;
    },
    getVenomizedPictureSrc: (state) => {
      return state.venomizedPictureSrc;
    },
    getDownloadablePictureSrc: (state) => {
      return state.downloadablePictureSrc;
    },
    uploadedImageFile: (state) => {
      return state.uploadedImageFile;
    },
    getCookiesAccepted: (state) => {
      return state.cookiesAccepted;
    },
    photoAspectRatio: (state) => {
      return state.photoAspectRatio;
    },
  },
  mutations: {
    setState(state, target) {
      state.state = target;
    },
    setFailScreen(state, failScreen) {
      state.failScreen = failScreen;
    },
    setLoading(state, target) {
      state.loading = target;
    },
    backendLink(state, target) {
      state.backendLink = target;
    },
    setUploadedPictureSrc(state, target) {
      state.uploadedPictureSrc = target;
    },
    setVenomizedPictureSrc(state, target) {
      state.venomizedPictureSrc = target;
    },
    setDownloadablePictureSrc(state, target) {
      state.downloadablePictureSrc = target;
    },
    uploadedImageFile(state, target) {
      state.uploadedImageFile = target;
    },
    setCookiesAccepted(state, target) {
      state.cookiesAccepted = target;
    },
    photoAspectRatio(state, target) {
      state.photoAspectRatio = target;
    },
  },
  actions: {
  },
};
