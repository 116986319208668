import { createApp } from "vue";
import Vuex from "vuex";
import UncopyableImage from "./components/UncopyableImage";
import App from "./App.vue";
import { store as storeApp } from "./store";
import { useLanguageModule, useTranslateFilter } from "./js/language";
import compatibility from "./js/compatibility";
import VueSplide from "@splidejs/vue-splide";

import {
  parsePosition,
  parseAnchoring,
  parseContainerFill,
  parseElementDimensions,
  parseMargins,
  parseElementStyle,
  parsePadding,
  getVH,
  getVW,
} from "./js/styleInjection";
import "./sass/App.scss";
import analytics from "@/js/analytics";
import mediapipe from "@/js/mediapipe";

export const store = new Vuex.Store({
  modules: {
    store: storeApp,
    language: useLanguageModule,
  },
});

const app = createApp(App).use(store);

app.use(VueSplide);
app.component("UncopyableImage", UncopyableImage);


app.provide('translate', useTranslateFilter);
app.provide('elementDimensions', parseElementDimensions);
app.provide('getPosition', parsePosition);
app.provide('elementStyle', parseElementStyle);
app.provide('margins', parseMargins);
app.provide('padding', parsePadding);
app.provide('containerFill', parseContainerFill);
app.provide('vw', getVW);
app.provide('vh', getVH);
const toUSD = (value) => `$${value}`;

app.provide('toUSD', toUSD);
app.mount("#app");

window.onload = function () {
  //router.replace({ path: "/" });
  //router.go(-1);
};

window.onhashchange = function () {
  //router.replace({ path: "/" });
  window.location.reload(false);
};
window.timeInApp = 0;
window.started = Date.now();

const setVDimensions = function () {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  let vw = window.innerWidth * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  document.documentElement.style.setProperty("--vw", `${vw}px`);
  document.documentElement.style.setProperty("--font-scale", `1`);
  //document.documentElement.style.setProperty("position", "absolute");
  //document.body.style.setProperty("position", "absolute");
  store.commit("setTrueVH", vh);
  store.commit("setTrueVW", vw);
};

const setViewportHeight = () => {
  setVDimensions();
  // We listen to the resize event
  window.addEventListener("resize", () => {
    // We execute the same script as before
    setVDimensions();

    window.setTimeout(() => {
      setVDimensions();
    }, 500);
  });
};

const setBackendLink = async () => {
  //console.log(store.getters.getLocale);

  let url = "https://sony-venomizer-php-d9504faf2682.herokuapp.com/";
  if(window.location.hostname.indexOf('intl.venomizemypet.com') > -1) {
    url = "./venomize.php";
  } else if(window.location.hostname.indexOf('venomizemypet.com') > -1) {
    url = "./venomize.php";
  }
  store.commit('backendLink', url);
};

//Logic to fix issues with camera feed freezing
const focusedPage = () => {
  window.started = Date.now();

  if (window.newWindow) window.newWindow.close();
  if (document.getElementsByTagName("video")[0])
    document.getElementsByTagName("video")[0].play();
  window.setTimeout(() => {
    if (document.getElementsByTagName("video")[0])
      document.getElementsByTagName("video")[0].play();
  }, 1500);
};

const blurPage = () => {
  window.timeInApp += Date.now() - window.started;
};

window.onbeforeunload = () => {
  window.timeInApp += Date.now() - window.started;

  //TAGGED: Might not need this
  document.querySelector("body").dispatchEvent(new Event("cleanupEverything"));
};
function loadScript(src) {
  return new Promise(function (resolve, reject) {
      var script = document.createElement('script');
      script.onload = function () {
        resolve();
      };
      script.onerror = function () {
        reject();
      };
      script.src = src;
      document.body.appendChild(script);
    }
  );
}
async function setupConsent() {
  setTimeout(async function () {
    await loadScript("consent-api-min-v2.js");
    //window.consentHelper.setConsentStyles();
    store.commit("setCookiesAccepted", window.consentHelper.getConsent('youtube'));
  }, 1000)

}
window.addEventListener("focus", focusedPage);
window.addEventListener("blur", blurPage);

// Start the scene and catch any bubbled exceptions
const start = async () => {
  try {
    setupConsent();
    await store.dispatch("language/fetchLanguage");
    analytics.init(useTranslateFilter('language-code'));

    console.log(useTranslateFilter('language-code'));

    await compatibility.checkDevice();
    setViewportHeight();
    await setBackendLink();
    await mediapipe.init();
  } catch (e) {
    store.commit("setFailScreen", "generic");
  }
};

start();



// catch any global errors
window.onerror = (e) => {
  store.commit("setFailScreen", "generic");
};
