<script setup>

import {ref, defineEmits, computed} from 'vue'

const props = defineProps({
  scrollAmount: { default: 0.0, type: Number },
  src: { default: '', type: String },
  desktopSrc: {default: '', type: String },
  heightScale: { default: 1.0, type: Number },
});

const getScrollOffset = computed(() => {
  return `${props.scrollAmount * -((props.heightScale - 1.0)*100)}%`;
});

const getHeight = computed(() => {
  return `${100*props.heightScale}%`;
});

</script>

<template>
  <div class="ScrollingBackground">
    <div class = "background flex-column" :style = "{top: getScrollOffset, height: getHeight}">
      <img v-if = "props.src != ''" class = "image mobile" :src = "props.src">
      <img v-if = "props.desktopSrc != ''" class = "image desktop" :src = "props.desktopSrc">
      <slot name = "content">

      </slot>
    </div>
  </div>
</template>

<style scoped lang='scss'>
@import '@/sass/GlobalClasses';
@import '@/sass/Settings';

.ScrollingBackground {
  @extend %fixed-fill;
  pointer-events: none;
  z-index: -1;
  *{
    pointer-events: none;
  }

  .background{
    position: absolute;
    left: 0;
    right: 0;

    .image{
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: auto;
      //@extend %absolute-fill;
    }
    .image.desktop {
      display: none;
    }
    .image.mobile {
      display: block;
    }
    @media screen and (min-aspect-ratio: 1/1) {
      .image.desktop {
        display: block;
      }
      .image.mobile {
        display: none;
      }
    }


  }
}

</style>