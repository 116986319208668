<script setup>
import FailGeneric from "@/views/fail_modules/FailGeneric.vue";
import {computed, onMounted} from "vue";
import {useStore} from "vuex";
import analytics from "@/js/analytics";

const store = useStore();
const failScreen = computed(() => store.getters.getFailScreen);


onMounted(() => {
  analytics.setPage("fail screen "+failScreen.value);
});
</script>

<template>
  <div style="height: fit-content; width: 100%">
    <FailGeneric
        v-if="failScreen == 'no-animal'"
        header="error-no-animal-message-1"
        body="error-no-animal-message-2"
    ></FailGeneric>
    <FailGeneric
        v-else-if="failScreen == 'human'"
        header="error-human-message-1"
        body="error-human-message-2"
        secondbody="error-human-message-3"
    ></FailGeneric>
    <FailGeneric
        v-else-if="failScreen == 'photo-issue'"
        header="error-rejected-message-1"
        body="error-rejected-message-2"
    ></FailGeneric>
    <FailGeneric
        v-else
        header="error-unknown-message-1"
        body="error-unknown-message-2"
        button=""
    ></FailGeneric>
  </div>
</template>

<style scoped lang="scss">

@import '../../sass/GlobalClasses';
@import '../../sass/Settings';

</style>